const DEFAULT_OPTIONS = {
    autoHide: false,
    classNames: {
        // defaults
        content: 'simplebar-content',
        scrollContent: 'simplebar-scroll-content',
        scrollbar: 'simplebar-scrollbar',
        track: 'simplebar-track'
    },
    direction: 'ltr',
    forceVisible: false,
    clickOnTrack: true,
    scrollbarMinSize: 25,
    scrollbarMaxSize: 0,
}


export default {
    mounted(el, binding, vnode, prevVnode) {
        const instance = new window.SimpleBar(el, {...DEFAULT_OPTIONS, ...(binding.value || {})});
        if (typeof binding.value?.scroll === 'function') {
            instance.getScrollElement().addEventListener('scroll', binding.value.scroll);
        }
    },
    beforeUnmount(el, binding, vnode, prevVnode) {
        if (typeof binding.value?.scroll === 'function') {
            const instance = window.SimpleBar.instances.get(el)
            instance.getScrollElement().removeEventListener('scroll', binding.value.scroll);
        }
    },
};
