export default {
    mounted(el) {
        if (typeof window !== 'undefined' && window.bootstrap) {
            el.tooltip = new window.bootstrap.Tooltip(el);
        }

    },
    beforeUnmount(el) {
        if (el.tooltip) {
            el.tooltip.dispose();
        }
    },
};
